<template>
  <b-card
    class="my-offers__single"
    :class="{ active: !offer.is_deactivated, highlight: highlight }"
  >
    <b-overlay :show="isLoading">
      <div class="my-offers__single-settings">
        <span class="activity">
          <span v-if="!offer.is_deactivated">Активне</span>
          <span v-else>Неактивне</span>
        </span>
        <toggleMenu>
          <template v-slot:list>
            <a
              href="#"
              v-if="!offer.is_deactivated"
              @click.prevent="changeOfferStatus"
              ><img
                src="@/assets/img/unactivate.svg"
                alt="unactivate"
              />Деактивувати</a
            >
            <a
              href="#"
              v-if="offer.is_deactivated"
              @click.prevent="changeOfferStatus"
              ><img
                src="@/assets/img/activate.svg"
                alt="activate"
              />Активувати</a
            >
            <a href="#" @click.prevent="startRemove" style="color: #cd0c0c"
              ><img src="@/assets/img/trash.svg" alt="trash" />Видалити</a
            >
          </template>
        </toggleMenu>
      </div>
      <div class="my-offers__single-data">
        <div class="my-offers__single-data-date pb-2">
          Додано {{ $moment(offer.createdAt).fromNow() }}
          <span v-if="offer.updated_by_user_at" class="edited">
            <img src="@/assets/img/dot.svg" alt="" />
            Оновлено {{ $moment(offer.updated_by_user_at).fromNow() }}
          </span>
        </div>
        <div class="my-offers__single-data-details d-block d-md-flex">
          <div
            class="my-offers__single-data-details-image d-none d-md-block"
            :style="'background-image: url(' + mapImgSrc + ')'"
          ></div>
          <div class="my-offers__single-data-details-text">
            <div class="title">
              {{ offer.location_title || "Location title" }}
            </div>
            <div class="details d-block d-md-flex">
              <div class="detail">Місць: {{ offer.persons }}</div>
              <div class="detail">Переглядів: {{ offer.persons }}</div>
              <div class="detail">Збережено: {{ offer.persons }}</div>
            </div>
          </div>
          <b-button class="ms-auto d-none" variant="outline-primary">
            <img class="me-1" src="@/assets/img/pencil.svg" alt="pencil" />
            Редагувати
          </b-button>
        </div>
      </div>
      <b-alert
        v-if="approved_by_ai == false"
        show
        variant="warning"
        class="mt-3 mb-0"
      >
        <b-icon-exclamation-triangle-fill /> Оголошення не відповідає
        <a href="#" @click.prevent="openGuidelines"> правилам сайту </a>
      </b-alert>
    </b-overlay>
  </b-card>
</template>

<script>
import ToggleMenu from "@/components/toggle-menu.vue";

export default {
  components: {
    ToggleMenu,
  },
  props: {
    offer: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      highlight: false,
    };
  },
  computed: {
    mapImgSrc() {
      let url = require("@/assets/img/fullmap.png");
      if (this.offer.location_lat && this.offer.location_lng) {
        url = `https://maps.googleapis.com/maps/api/staticmap?center=Berkeley,CA&zoom=14&size=400x400&key=AIzaSyAfNssi3issHmfxP3Run4A7Uaearyag8zI`;
      }

      return url;
    },
  },
  methods: {
    changeOfferStatus() {
      this.isLoading = true;
      this.$api
        .post(`/houses/update/${this.offer.id}`, {
          is_deactivated: !this.offer.is_deactivated,
        })
        .then(() => {
          this.isLoading = false;
          this.$toast.success(
            `Оголошення успішно ${
              this.offer.is_deactivated ? "активовано" : "деактивовано"
            }`
          );
          this.$emit("update");
        })
        .catch(() => {
          this.$toast.error("Помилка при зміні статусу оголошення");
          this.isLoading = false;
        });
    },
    startRemove() {
      const event = new CustomEvent("openRemoveModal", {
        detail: { house: this.offer },
      });
      window.dispatchEvent(event);
    },
    openGuidelines() {
      const event = new CustomEvent("openGuidelines");
      window.dispatchEvent(event);
    },
  },
  mounted() {
    if (this.$route.query.id) {
      window.setTimeout(() => {
        this.highlight = Number(this.$route.query.id) === this.offer.id;
      }, 300);
      window.setTimeout(() => {
        this.highlight = false;
      }, 5000);
    }
  },
};
</script>

<style lang="scss" scoped>
.my-offers {
  &__single {
    padding: 6px;
    margin-bottom: 16px;
    transition: box-shadow 0.5s;
    &.highlight {
      box-shadow: 0px 5px 18px 0px rgba(#000, 0.2);
      transition: box-shadow 0.5s;
    }
    &-settings {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .activity {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #888888;
        font-size: 14px;
        line-height: 1.2;
        background: #f7f7f7;
        border-radius: 24px;
        padding: 8px 12px;
        p {
          margin: 0;
        }
      }
    }
    &-data {
      &-date {
        font-size: 14px;
        margin-bottom: 8px;
        line-height: 1.2;
        font-weight: 400;
        display: flex;
        align-items: center;
        opacity: 0.7;
        .edited {
          display: flex;
          align-items: center;
          img {
            margin: 0px 6px;
          }
        }
        @media screen and (max-width: 767px) {
          align-items: unset;
          flex-direction: column-reverse;
          .edited {
            color: var(--primary-blue);
            img {
              display: none;
            }
          }
        }
      }
      &-details {
        &-image {
          opacity: 0.7;
          min-width: 64px;
          height: 64px;
          border-radius: 12px;
          background-position: center;
          background-size: cover;
          margin-right: 16px;
        }
        &-text {
          opacity: 0.7;
          .title {
            font-size: 20px;
            line-height: 1.2;
            margin-bottom: 16px;
          }
          .details {
            flex-wrap: wrap;
            .detail {
              display: flex;
              align-items: center;
              margin-right: 24px;
              font-size: 14px;
              line-height: 1.2;
              margin-bottom: 8px;
              img {
                margin-right: 5px;
              }
            }
          }
        }
        button {
          font-weight: 500;
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-top: 16px;
          }
        }
      }
    }
    &.active {
      .activity {
        color: #00af1c;
        background: #e5f7e8;
      }
      .my-offers__single-data-details-text,
      .my-offers__single-data-details-image,
      .my-offers__single-data-date {
        opacity: 1;
      }
    }
  }
}
</style>
