<template>
  <b-modal v-model="showModal" hide-header hide-footer>
    <h4 class="mb-3">Шановний користувачу,</h4>

    <p>
      Звертаємо вашу увагу на те, що на нашому сервісі слід публікувати лише
      оголошення з пропозицією безкоштовного житла для вимушених переселенців.
    </p>
    <p>
      Розміщення оголошень комерційного характеру, оголошень із прихованими
      пропозиціями стосунків, а також оголошень, де користувач шукає житло,
      заборонено.
    </p>
    <p>
      Оголошення, що не відповідають правилам, будуть модеровані не будуть
      показані на сайті.
    </p>

    <p>
      <strong>
        Дякуємо за ваше розуміння.
        <br />
        Команда «Прихистку»
      </strong>
    </p>
    <div class="cta">
      <b-button
        variant="outline-primary"
        @click="showModal = false"
        class="w-100"
        >Закрити</b-button
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  mounted() {
    window.addEventListener("openGuidelines", () => {
      this.showModal = true;
    });
  },
};
</script>

<style lang="scss" scoped></style>
