<template>
  <div class="create pt-4 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8">
          <h1>Створити оголошення про можливість прийняти людей</h1>
        </div>
        <div class="col-12 col-md-9">
          <b-card class="mb-3">
            <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
              <div class="d-flex align-items-center">
                <label class="me-2"> Розташування </label>
              </div>
              <errorValidation
                label="Поле обов'язкове"
                :show="!valid.location && validate"
              />
              <div class="fake-input">
                <b-icon icon="geo-alt" class="mr-2"></b-icon>
                <locationInput
                  @setLocation="setLocation"
                  :placeholder="'Почніть вводити назву нас. пункту'"
                />
              </div>
              <div class="form-input mt-4">
                <div class="d-flex align-items-center">
                  <label class="me-2" for="address"
                    >Розкажіть детальніше про помешкання</label
                  >
                </div>
                <errorValidation
                  label="Поле обов'язкове"
                  :show="!valid.notes && validate"
                />
                <b-form-textarea
                  class="p-3 mt-2"
                  v-model="house.notes"
                  placeholder="Наприклад, приватний будинок, є газ, вода, опалення, 3 ліжка та 1 розкладний диван. Можу прийняти вже зараз на 3-4 дні"
                  rows="4"
                ></b-form-textarea>
              </div>
              <small>
                Оголошення повинно відповідати
                <a href="#" @click.prevent="openGuidelines"> правилам сайту </a>
              </small>
            </b-overlay>
          </b-card>
          <b-card class="mb-3">
            <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
              <div
                class="d-flex flex-column flex-md-row align-items-md-center justify-content-start justify-content-md-between"
              >
                <label class="mb-3 mb-md-0"
                  >Яку кількість осіб ви готові розмістити</label
                >

                <personsInput v-model="house.persons" @update="setPersons" />
              </div>
            </b-overlay>
          </b-card>
          <b-card class="mb-3">
            <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
              <div
                class="mb-2 mb-lg-2 d-flex align-items-center justify-content-center justify-content-between"
              >
                <label class="mb-0"
                  >Кого ви готові розмістити
                  <errorValidation
                    label="Поле обов'язкове"
                    :show="!valid.persons_type && validate"
                  />
                </label>

                <div class="customCheckbox__wrapper">
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="d-flex">
                      <div class="me-2 d-none d-lg-block">❤️️</div>
                      <customCheckbox v-model="any_persons" label="Будь-кого" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-2"></div>

              <div>
                <custom-badges
                  :options="datasets.persons_type"
                  :has-icon="false"
                  :value="house.persons_type"
                  :type="'persons_type'"
                  :isDisabled="any_persons"
                  :key="house.persons_type.length"
                  @select="handleSelect"
                />
              </div>
            </b-overlay>
          </b-card>
          <b-card class="mb-3">
            <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
              <div
                class="d-flex flex-column flex-md-row align-items-md-center justify-content-start justify-content-md-between"
              >
                <label class="mb-2 mb-md-0"
                  >На який період готові надати прихисток</label
                >
              </div>
              <errorValidation
                label="Поле обов'язкове"
                :show="!valid.period && validate"
              />
              <div class="mt-3">
                <custom-badges
                  :options="datasets.periods"
                  :has-icon="true"
                  @select="handleSelect"
                  :type="'period'"
                />
              </div>
            </b-overlay>
          </b-card>
          <b-card class="mb-3">
            <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
              <div
                class="d-flex flex-column flex-md-row align-items-md-center justify-content-start justify-content-md-between"
              >
                <label class="mb-2 mb-md-0">Тип розміщення</label>
              </div>
              <errorValidation
                label="Поле обов'язкове"
                :show="!valid.accomodation_type && validate"
              />
              <div class="mt-3">
                <custom-badges
                  :options="datasets.accomodation_types"
                  :has-icon="true"
                  @select="handleSelect"
                  :type="'accomodation_type'"
                />
              </div>
            </b-overlay>
          </b-card>
          <b-card class="mb-3">
            <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
              <label class="mb-2 d-md-flex align-items-center flex-wrap"
                >Контактний телефон

                <div
                  v-if="isPhoneVerified"
                  class="phone_green mt-2 mt-md-0 ms-md-auto d-flex"
                >
                  <img src="@/assets/img/Approve.svg" alt="" />
                  <div class="phone_text mt-0 mt-md-0">Номер веріфікованно</div>
                </div>
              </label>
              <div
                class="d-flex flex-column flex-md-row align-items-md-center justify-content-start justify-content-md-between"
              >
                <div class="w-100">
                  <b-alert variant="warning" :show="!isPhoneVerified && isUser">
                    Для того, щоб ваше оголошення було опубліковано, вам
                    потрібно підтвердити номер телефону
                    <div class="mt-2">
                      <b-button @click="openAddPhone" variant="primary">
                        Підтвердити номер
                      </b-button>
                    </div>
                  </b-alert>
                </div>
              </div>
              <div v-if="isPhoneVerified" class="phone mt-3">
                <div class="form-input mb-3">
                  <div class="col-12 col-md-6">
                    <b-form-input disabled :value="user.phone" />
                  </div>
                </div>
                <div class="search-filter d-flex align-items-center">
                  <label for="show-phone" class="filter-checkbox">
                    <input
                      id="show-phone"
                      type="checkbox"
                      v-model="house.show_phone"
                    />
                    <span class="fake-checkbox">
                      <b-icon-check />
                    </span>
                    Показати номер телефону в оголошенні
                  </label>
                </div>
              </div>
            </b-overlay>
          </b-card>
          <b-card class="mb-3 d-flex justify-content-start">
            <b-overlay :show="isLoading" spinner-variant="primary" rounded="lg">
              <div class="search-filter d-flex align-items-center">
                <label for="busy" class="filter-checkbox">
                  <input id="busy" type="checkbox" v-model="house.is_busy" />
                  <span class="fake-checkbox">
                    <b-icon-check />
                  </span>
                  У мене вже живуть біженці
                </label>
              </div>
              <div class="form-wrapper" v-if="house.is_busy">
                Ваше житло не буде опубліковано а лише внесене у базу для
                подальшого отримання компенсації. Вам потрібно додатково
                звернутись в органи місцевого самоврядування.
              </div>
            </b-overlay>
          </b-card>
        </div>
        <div class="col-12 col-md-3">
          <b-card>
            <div class="info">
              <img
                src="@/assets/img/shield.png"
                class="mb-4 d-block"
                width="96"
                alt=""
              />
              <p>
                <strong> Увага!</strong>
              </p>
              <p>
                Пам’ятайте про заходи безпеки: Перш, ніж надавати біженцям
                адресу свого проживання та заселяти їх,<strong>
                  обов’язково перевірте документи кожної особи</strong
                >, якій допомагаєте.
              </p>
              <p>
                З обережністю селіть до себе групи більше 1-ї сім’ї, особливо -
                якщо це люди з різними прізвищами.
              </p>
              <p>
                <strong>Рекомендуємо</strong> просити ваших гостей розповісти
                трохи про тих, хто їде з ними. Хто вони, чим займаються, тощо.
              </p>
              <p class="mb-0">
                <strong>Разом переможемо!</strong>
              </p>
            </div>
          </b-card>
        </div>

        <div class="col-12 col-md-9">
          <b-alert :show="!isUser" variant="primary">
            Для того, щоб створити оголошення, вам потрібно
            <a href="#" @click.prevent="openAuthModal">
              увійти або зареєструватись
            </a>
          </b-alert>
          <div class="mt-2 mt-md-0 mb-3" v-if="isPhoneVerified">
            Розміщаючи оголошення на цьому сайті, Ви погоджуєтесь із ключовою
            умовою - житло надається безкоштовно
          </div>
          <div class="d-block d-md-flex align-items-md-center">
            <button
              class="btn btn-create btn-primary mb-2 mb-md-0"
              style="white-space: nowrap"
              v-if="isPhoneVerified"
              @click="createOffer"
            >
              Створити оголошення
            </button>
            <div class="ms-2 py-3">
              <errorValidation
                label="Деякі поля містять помилки або не заповнені"
                :show="!valid.all && validate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import locationInput from "@/components/search/locationInput.vue";
import personsInput from "@/components/search/perons-count-input.vue";
import customCheckbox from "@/components/search/customCheckbox.vue";
import customBadges from "@/components/search/customBadges.vue";
import errorValidation from "@/components/error-validation.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    locationInput,
    personsInput,
    customCheckbox,
    customBadges,
    errorValidation,
  },
  data() {
    return {
      isLoading: false,
      location: {},
      validate: false,
      any_persons: false,
      house: {
        persons: 1,
        notes: "",
        phone: "",
        location_title: "",
        location_lat: "",
        location_lng: "",
        is_busy: false,
        show_phone: false,
        is_international: false,
        accomodation_type: [],
        period: [],
        persons_type: [],
      },
    };
  },
  watch: {
    any_persons: function (newVal) {
      if (newVal) {
        let allIds = this.datasets.persons_type.map((item) => item.id);
        this.$set(this.house, "persons_type", allIds);
      } else {
        //set value
        this.$set(this.house, "persons_type", []);
      }
    },
  },
  computed: {
    ...mapGetters(["datasets", "user", "isPhoneVerified", "isUser"]),
    valid() {
      let valid = {
        location: true,
        notes: true,
        persons_type: true,
        period: true,
        accomodation_type: true,
      };
      if (!this.house.location_lat || !this.house.location_lng) {
        valid.location = false;
      }
      if (!this.house.notes.length > 0) {
        valid.notes = false;
      }
      if (
        this.house.persons_type.length === 0 &&
        !this.house.is_international
      ) {
        valid.persons_type = false;
      }
      if (this.house.period.length === 0) {
        valid.period = false;
      }
      if (this.house.accomodation_type.length === 0) {
        valid.accomodation_type = false;
      }
      valid.all =
        valid.location &&
        valid.notes &&
        valid.persons_type &&
        valid.period &&
        valid.accomodation_type;
      return valid;
    },
  },

  methods: {
    openGuidelines() {
      const event = new CustomEvent("openGuidelines");
      window.dispatchEvent(event);
    },
    openAddPhone() {
      const event = new CustomEvent("openPhoneVerifyModal");
      window.dispatchEvent(event);
    },
    openAuthModal() {
      this.$store.dispatch("openAuthModal");
    },
    setPersons(value) {
      this.house.persons = value;
    },
    setLocation(value) {
      this.house.location_title = value.location_title;
      this.house.location_lat = value.location_lat;
      this.house.location_lng = value.location_lng;
      this.location = value.place;
    },
    handleSelect(selected) {
      this.$set(this.house, selected.key, selected.selected);
    },
    createOffer() {
      this.validate = true;
      const fieldsValid = this.validateFields();
      if (fieldsValid) {
        this.isLoading = true;
        let payload = { ...this.house };
        if (this.user.phone) {
          payload.phone = this.user.phone;
        }
        this.$api
          .post("/houses/create", payload)
          .then((response) => {
            if (response.data.success) {
              this.$toast.success("Оголошення успішно створено");
              this.$router.push(`/my/offers?id=${response.data.house.id}`);
            } else {
              this.$toast.error("Помилка при створенні оголошення");
            }
          })
          .catch(() => {
            this.$toast.error("Помилка при створенні оголошення");
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$toast.warning("Заповніть всі обов'язкові поля");
      }
    },
    validateFields() {
      let valid = true;
      for (const key in this.valid) {
        if (!this.valid[key]) {
          valid = false;
        }
      }
      return valid;
    },
  },
};
</script>

<style lang="scss" scoped>
.create {
  label {
    font-size: 16px;
    font-weight: 700;
    display: block;
    margin-bottom: 3px;
    @media screen and (max-width: 768px) {
      max-width: 150;
    }
  }
  .fake-input {
    border: 1px solid #ced4da;
    border-radius: 12px;
    height: 42px;
    padding: 0px 12px;
    position: relative;
    padding-left: 36px;
    margin-top: 6px;
    &:focus-within {
      border-color: #007bff;
      box-shadow: 0px 0px 0px 1px #007bff;
      transition: 0.3s;
    }
    .b-icon {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
    }
  }
  .info {
    p {
      font-size: 14px;
    }
  }
}
.customCheckbox {
  &__label {
    margin-right: 33px;
  }
  &__wrapper {
    border-radius: 32px;
    background-color: #f7f7f7;
    padding: 10px 20px;
    img {
      width: 24px;
      height: 24px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media screen and (max-width: 462px) {
      max-height: 50px;
    }
    @media screen and (max-width: 375px) {
      max-width: 160px;
    }
  }
}
.phone {
  &_text {
    margin-left: 4px;
    color: #00af1c;
  }
}
.form {
  &-input {
    input[type="checkbox"] {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      border-radius: 15px;
      &:focus {
        outline: 0 !important;
        border: none;
        box-shadow: none !important;
      }
    }
    div {
      //font-family: "Fixel Text SH";
      font-size: 14px;
    }
  }
  &-wrapper {
    margin-top: 16px;
    padding: 12px 16px;
    background-color: #e4edf9;
    color: #004bc1;
    border-radius: 12px;
  }
}
.btn-create {
  padding: 12px 20px !important;
}
.search-filter {
  .filter-checkbox {
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    cursor: pointer;
    input {
      display: none;
    }
    input:checked + .fake-checkbox {
      background: var(--primary-blue);
      border-color: var(--primary-blue);
      color: #fff;
    }
    .fake-checkbox {
      position: absolute;
      top: 0px;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid #dcdcdc;
      border-radius: 4px;
      background: #fff;
      color: transparent;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
